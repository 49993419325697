export const Data = {
  profile: {
    image: "./profile.jpg",
    name: "Noel Williams",
    occupation: "End User Services Technician",
    location: "Canberra, Australia",
    email: "mail[at]noelwilliams.au",
    phone: "+61 488 040 391",
    profileLabel: "Profile",
    profile: "I have a passion for technology, innovation and creating things. I enjoy developing useful tools and applications and expanding my knowledge in all areas of IT.",
    skillsLabel: "Skills",
    skills: [
      "Python",
      "Javascript",
      "Powershell",
      "Web Apps",
      "User Administration",
      "Linux",
      "Docker",
    ],
    interestsLabel: "Interests",
    interests: [
      "Self-Hosting",
      "Web Design",
      "Programming",
      "Digital Media",
      "Snooker",
      "Video games"
    ],
    socialLabel: "Social",
    socials: [
      {
        label: "Github",
        name: "github",
        url: "https://github.com/noelsy237",
        className: "bxl-github",
      },
      {
        label: "LinkedIn",
        name: "linkedin",
        url: "https://linkedin.com/in/noel-williams237",
        className: "bxl-linkedin-square",
      }
    ],
  },
  timeline: {
    experiences: [
      {
        title: "Service Desk Analyst",
        period: "May 2023 - Jul 2024",
        company: "Digital61",
        country: "AU",
        description: "Level 1/2 troubleshooting, AD user administration, Powershell scripting, hardware provisioning, asset management, app deployment, MS Exchange administration.",
      },
      {
        title: "End User Services Technician",
        period: "Jul 2024 - Present",
        company: "Digital61",
        country: "AU",
        description: "Group policy management, Powershell scripting, SCCM Management, ITIL Change Management, Applocker, App Packaging.",
      }
      
    ],
    educations: [
      {
        title: "Higher School Certificate",
        period: "2013 - 2018",
        institution: "Yanco Agricultural High School",
        country: "AU",
        description: "Top mark for General Mathematics",
        additionalInfo: [
          "English Advanced",
          "General Mathematics",
          "Business Studies",
          "Physics",
          "Spanish Beginners",
          "Hospitality Certificate III"
        ]
      },
      {
        title: "Bachelor of Information Technology",
        period: "2020 - 2024",
        institution: "University of Canberra",
        country: "AU",
        description: "Completed in November 2024",
        additionalInfo: [
          "Major in Information Technology",
          "Professional Practice in IT",
          "Introduction to Network Engineering",
          "Introduction to Information Technology",
          "Database Design",
          "Technological Innovation and Entrepreneurship",
          "Systems Analysis and Modelling",
          "Software Technology 1",
          "Discrete Mathematics",
          "Software Technology 2",
          "Information Security",
          "Front-end Web Design",
          "Designing Human-Computer Interaction",
          "Web Design and Programming",
          "Software Systems Architecture",
          "Programming for Design",
          "Information Systems in Organisations",
          "Technology and Engineering Management",
          "Systems Project and Quality Management",
          "Enterprise and Cloud Computing",
          "Web Frameworks and Dynamic Data",
          "Information & Communication Technology Project",
          "System and Network Administration",

        ]
      },
    ],
    projects: [
      {
        name: "Movie Db",
        company: "Personal",
        period: "Oct 2022",
        description: "A website for showcasing movie lists",
        url: "https://github.com/noelsy237/assignment-3-interactive-project"
      },
      {
        name: "Tarkov Bot",
        company: "Personal",
        period: "Nov 2022",
        description: "A Discord bot for the game Escape from Tarkov",
        url: "https://github.com/noelsy237/TarkovBot"
      },
      {
        name: "Allbids True Price",
        company: "Personal",
        period: "Jun 2024",
        description: "A browser extension for displaying post-fee prices on allbids.com.au",
        url: "https://github.com/noelsy237/assignment-3-interactive-project"
      }
     
    ],
  },
};
